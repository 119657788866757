import React from "react";

// Components
import { EAppointmentFrame } from "../components/HotDocFrame";

const BookAppointment = () => {
    return (
        <>
            <div className="iframe py-4">
                <EAppointmentFrame />
            </div>
        </>
    );
};

export default BookAppointment;
